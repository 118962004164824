.report-succeeded__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    box-sizing: border-box;
    overflow: auto;

    .main {
      padding: 2em;
    }

    .text_default,
    .text_default_gray {
        text-align: center;
        justify-content: center;
        display: block;
    }
    a.link {
        display: inline;
        cursor: pointer;
    }

    .google-play_link,
    .app-store_link {
        margin: 1vh;
    }

    .html-markup__container {
        padding: 0;
    }

    .report-succeeded__thank-you-text {
        display: block !important;
        font-size: 14px !important;
        margin-bottom: 12px !important;
    }
}

.report-succeeded__container .main,
.report-succeeded__navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100% !important;

    > div,
    .report-succeeded__messages-button,
    .report-succeeded__home-button {
        margin-bottom: 2vh;
    }

    .creds {
        .text_enlarged {
            justify-content: center;
            white-space: pre;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.report-succeeded__container {
    .input_label {
      margin-bottom: 8px !important;
    }
    .interactive-input__container {
        margin-top: 0 !important;
    }
    .text_block {
        margin-bottom: 16px !important;
    }
}

.report-succeeded__logo {
    min-width: 159px;
    min-height: 46px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../static/images/Logo.svg");
    background-size: contain;
}

.report-succeeded__success-icon {
    min-width: 62px;
    min-height: 62px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../static/images/success-check-icon.svg");
    margin: 2vh auto;
    background-size: contain;
}

.report-succeeded__download-apps-icons__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
